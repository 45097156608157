import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import * as querydata from "../dataService/DataService";
import backarrow from "../../assets/images/back-arrow.svg";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getRestaurantType } from "../dataService/DataService";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumView from "../manageAlbum/AlbumViewForDinning";
import MultipleRestaurantTag from "./MultiRestaurantTag";
// import RestaurantDetail from "./RestaurantDetail";
import RestaurantDetailEdit from "./RestaurantDetailEdit";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import pdfImg from "../../assets/images/pdf-doc.svg";
import RestaurentTags from "./RestaurentTags";
import RestaurantTag2 from "./RestaurantTag2";
import { useQueryClient } from "react-query";
import { useContext } from "react";
import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
// import MultipleImageAltTag from "./MultipleImageAltTag";
import { getMandatoryFields, getExtraFields } from "../../mandatoryfileds";
import { GlobalContext } from "../../globalContext";
import { faPlus, faTrash, faTrashAlt, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResortTypeModal from "./ResortTypeModal";
import RestaurantSelect from "./RestaurantSelect";
import DeleteRestaurantTypeModal from "./DeleteRestaurantTypeModal";
export default function EditDining() {
  const { id } = useParams();
  const { bwebsiteId } = useContext(GlobalContext);
  const moduleName = "dinning";
  // Get the mandatory fields for the specific websiteId and moduleName
  const mandatoryFields = getMandatoryFields(bwebsiteId, moduleName);
  const extraFields = getExtraFields(bwebsiteId, moduleName);
  const RestaurantSchema = () =>
    Yup.object({
      RestaurantName: Yup.string()
        .required("Please enter restaurant name")
        .trim(),
      highlightsTitle: Yup.string()
        .trim()
        .test(
          "is-mandatory-highlightsTitle",
          "Please enter highlights title",
          function (value) {
            return (
              !extraFields.includes("highlightsTitle") ||
              (value && value.trim() !== "")
            );
          }
        ),
      highlightsDescription: Yup.string()
        .trim()
        .test(
          "is-mandatory-highlightsDescription",
          "Please enter highlights description",
          function (value) {
            return (
              !extraFields.includes("highlightsDescription") ||
              (value && value.trim() !== "")
            );
          }
        ),
      // highlightsTitle: Yup.string()
      //   .required("Please enter highlights title ")
      //   .trim(),
      // highlightsDescription: Yup.string()
      //   .required("Please enter highlights description ")
      //   .trim(),
      RestaurantTagLine: Yup.string()
        .required("Please enter restaurant tagline")
        .trim(),

      RestaurantDescription: Yup.string()
        .required("Please enter restaurant description")
        .trim(),

      slugName: Yup.string()
        .matches(
          /^[a-zA-Z0-9_-]*$/,
          "Only alphanumeric characters and underscores are allowed"
        )
        .required("Please enter slug"),
ambienceTitle: Yup.string()
        .trim()
        .test(
          "is-mandatory-ambienceTitle",
          "Please enter ambience title",
          function (value) {
            return (
              !extraFields.includes("ambienceTitle") ||
              (value && value.trim() !== "")
            );
          }
        ),
      metaTitle: Yup.string().trim(),
      keyWords: Yup.string().trim(),
      metaDescription: Yup.string().trim(),

      dataArray: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("This field requires a heading").trim(),

          tagDescription: Yup.string()
            .trim()
            .test(
              "is-mandatory-tagDescription",
              "This field requires a description",
              function (value) {
                return (
                  !mandatoryFields.includes("tagDescription") ||
                  (value && value.trim() !== "")
                );
              }
            ),

          tagFeatures: Yup.array()
            .of(
              Yup.string()
                .required("Please add at least one tag feature")
                .trim()
            )
            .test(
              "is-mandatory-tagFeatures",
              "At least one tag feature is required",
              function (value) {
                return (
                  !mandatoryFields.includes("tagFeatures") ||
                  (value && value.length > 0)
                );
              }
            ),
        })
      ),
    });
  //  const RestaurantSchema = Yup.object().shape({
  //   RestaurantName: Yup.string().required("Please enter restaurant name"),
  //   highlightsTitle: Yup.string()
  //     .required("Please enter highlights title ")
  //     .trim(),
  //   highlightsDescription: Yup.string()
  //     .required("Please enter highlights description ")
  //     .trim(),
  //   RestaurantTagLine: Yup.string().required("Please enter restaurant Tagline"),
  //   RestaurantDescription: Yup.string().required(
  //     "Please enter restaurant description"
  //   ),

  //   slugName: Yup.string()
  //     .matches(
  //       /^[a-zA-Z0-9_-]*$/,
  //       "Only alphanumeric characters, hyphens, and underscores are allowed"
  //     )
  //     .required("Please enter slug"),
  //   metaTitle: Yup.string().trim(),
  //   keyWords: Yup.string().trim(),
  //   metaDescription: Yup.string().trim(),

  //   dataArray: Yup.array().of(
  //     Yup.object().shape({
  //       name: Yup.string().required("This field requires a heading"),
  //       tagDescription: Yup.string().trim().test(
  //         'is-tagDescription-required',
  //         "This field requires a description",
  //         function (value) {
  //             return !extraFields.includes('tagDescription') || !!value; // If discountText is required, ensure it's not empty
  //         },
  //       ),

  //       tagFeatures: Yup.string().trim().test(
  //         'is-tagFeatures-required',
  //         "Please add atleast one tag Feature",
  //         function (value) {
  //             return !extraFields.includes('tagFeatures') || !!value; // If discountText is required, ensure it's not empty
  //         },
  //       ),
  //       // tagDescription: Yup.string(),//condition

  //       // tagFeatures: Yup.array()//condition
  //       //   .of(Yup.string().required("Please add atleast one tag Feature").trim())
  //       //   .min(1, "At least one tag feature is required"),
  //     })
  //   ),
  // });
  const getSingleRestaurant = useQuery(
    ["Restaurant", id],
    () => querydata.getSingleRestaurant(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [editsection, setsectionImage] = useState();
  const getLeftRightsettingvalue = useQuery(
    ["AspectRatio", "dining_left_image_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_left_image_aspect_ratio")
  );
  const getChefSignatureAspectRatio = useQuery(
    ["AspectRatio", "dining_chef_signature_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_chef_signature_aspect_ratio")
  );
  const getRestaurantLogoAspectRatio = useQuery(
    ["AspectRatio", "dining_restaurant_logo_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_restaurant_logo_aspect_ratio")
  );

  let AllRestaurantTypeId = useQuery("getAllRestaurantTypes", getRestaurantType);
  const navigate = useNavigate();
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [maincroppedSecImage, setmainCroppedSecImage] = useState(null);
  const [maincroppedThirdImage, setmainCroppedThirdImage] = useState(null);
  const [maincroppedSecFile, setmainCroppedSecFile] = useState(null);
  const [maincroppedThirdFile, setmainCroppedThirdFile] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkValForSingleImg3, setValForSingleImg3] = useState("");
  const [checkValForIcon, setValForIcon] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [currentFileId, setCurrentFileId] = useState("");
  const [mainCroppedIcon, setMainCroppedIcon] = useState(null);
  const [mainCroppedIconFile, setmainCroppedIconFile] = useState(null);
  const [icon, setIcon] = useState(null);
  const [BannerImage, setBannerImage] = useState(null);
  const [maincroppedBannerImage, setMainCroppedBannerImage] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setAction] = useState();
  const [mainCroppedBannerImageFile, setmainCroppedBannerImageFile] =
    useState(null);
  const [checkValForBannerImage, setValForBannerImage] = useState(null);
  const [settingValue, setSettingValue] = useState("");
  const [imgArray, setImgArray] = useState(null); //use to get the index of the selected detail array Image
  const closeCropMediaModal = () => setCropMediaModal(false);

  // menu work start
  const [restMenu, setrestMenu] = useState([]);
  const [restMenuValue, setrestMenuValue] = useState();
  const [indexForMenu, setIndexForMenu] = useState();

  //restaurant logo

  const [restLogo, setrestLogo] = useState();
  const [restLogoValue, setrestLogoValue] = useState();

  //for left image
  const [leftImage, setLeftImage] = useState();
  const [leftImageValue, setLeftImageValue] = useState();

  //for righrt  image
  const [rightImage, setRightImage] = useState();
  const [rightImageValue, setRightImageValue] = useState();

  //for chief signature image

  const [chefSignatue, setChefSignatue] = useState();
  const [chefSignatueValue, setChefSignatueValue] = useState();

  const [showModalImageTagModal, setImageTagModal] = useState(false);
  const handleShowImageTagModal = () => {
    setImageTagModal(true);
  };

  const handleCloseImageTagModal = () => {
    setImageTagModal(false);
  };
  const restaurantLogoAspectRatio = useQuery(
    ["AspectRatio", "	dining_logo_aspect_ratio"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_logo_aspect_ratio")
  );

  const showCropMediaModal = (id, val, index) => {
    setCurrentFileId(id);
    if (val === "firstImage") {
      setValForSingleImg(val);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue(getCardImageAspectRatio?.data?.data?.setting_value);
    }
    if (val === "secondImage") {
      setMultipleImageSection(val);
      setValForSingleImg("");
      setValForIcon(null);
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }
    if (val === "thirdImage") {
      setImgArray(index);
      setValForSingleImg3(val);
      setMultipleImageSection(null);
      setValForSingleImg(null);
      setValForBannerImage(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }
    if (val === "BannerImage") {
      setValForBannerImage(val);
      setValForSingleImg(null);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setSettingValue(getInnerImageAspectRatio?.data?.data?.setting_value);
    }
    if (val === "restaurantIcon") {
      setValForIcon(val);
      setValForSingleImg(null);
      setValForSingleImg3(null);
      setMultipleImageSection(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }

    //new code
    if (val === "retsmenu") {
      setIndexForMenu(index);
      setrestMenuValue(val);
    }
    if (val === "restLogo") {
      setrestLogoValue(val);

      setSettingValue(
        getRestaurantLogoAspectRatio?.data?.data?.setting_value
          ? getRestaurantLogoAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "leftImage") {
      setLeftImageValue(val);
      setSettingValue(
        getLeftRightsettingvalue?.data?.data?.setting_value
          ? getLeftRightsettingvalue?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "rightImage") {
      setRightImageValue(val);
      setSettingValue(
        getLeftRightsettingvalue?.data?.data?.setting_value
          ? getLeftRightsettingvalue?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (val === "chefSignature") {
      setChefSignatueValue(val);
      setSettingValue(
        getChefSignatureAspectRatio?.data?.data?.setting_value
          ? getChefSignatureAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    setCropMediaModal(true);
  };
  const queryClient = useQueryClient();

  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "dining_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_image_dimension")
  );
  const getInnerImageAspectRatio = useQuery(
    ["AspectRatio", "dining_inner_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_inner_image_dimension")
  );

  const getCardImageAspectRatio = useQuery(
    ["AspectRatio", "dining_card_image_dimension"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_card_image_dimension")
  );

  const handleRemoveImage = (e, value) => {
    if (value === "RestaurantLogo") {
      setrestLogo(null);
      // settingValue(getLeftImageRatioAspectRatio?.data?.data?.)
    }
    if (value === "leftImage") {
      setLeftImage(null);
    }
    if (value === "rightImage") {
      setRightImage(null);
    }
    if (value === "chefSignature") {
      setChefSignatue(null);
    }
    // image = undefined;
    // Handle removing the image
    if (value === "maincroppedImage") {
      setImage(undefined);
      setmainCroppedImage(undefined);
    }
  };
  const handleBannerImage = (e, image) => {
    image = undefined;
    // Handle removing the image
    if (e) {
      setBannerImage(undefined);
      setMainCroppedBannerImage(undefined);
    }
  };
  const handleSecRemoveImage = (e) => {
    // Handle removing the image
    if (e) {
      setmainCroppedSecFile(null);
      setImage2(undefined);
      setmainCroppedSecImage(undefined);
    }
  };
  const handleRemoveIcon = (e) => {
    // Handle removing the image
    setmainCroppedIconFile(null);
    setMainCroppedIcon(null);
    if (e) {
      setIcon(null);
      setMainCroppedIcon(null);
    }
  };

  const handleThirdRemoveImage = (e, sectionId, index) => {
    // Handle removing the image
    setsectionImage(sectionId);
    e.preventDefault();
    if (maincroppedThirdFile !== null) {
      const data = { ...maincroppedThirdFile };
      data[imgArray] = null;
      setmainCroppedThirdFile(data);
    }

    if (e) {
      setImage3((prevData) => {
        const updatedData = { ...prevData };

        delete updatedData[sectionId];
        return updatedData;
      });
    }
  };

  const [restaurantName, setRestaurantName] = useState(
    getSingleRestaurant?.data?.data?.data[0].restaurant_name || ""
  );

  const [restaurantDescription, setRestaurantDescription] = useState(
    getSingleRestaurant?.data?.data?.data[0].restaurant_description || ""
  );
  const [restaurantTypeId, setRestaurantTyprId] = useState(
    getSingleRestaurant?.data?.data?.data[0].restaurant_type_id
  );

  const [restaurandDetailDataArray, setRestaurandDetailDataArray] = useState(
    []
  );

  useEffect(() => {
    setRightImage(
      getSingleRestaurant?.data?.data?.data[0]?.right_image ?? undefined
    );
    setLeftImage(
      getSingleRestaurant?.data?.data?.data[0]?.left_image ?? undefined
    );
    setChefSignatue(
      getSingleRestaurant?.data?.data?.data[0]?.chef_signature ?? undefined
    );
    setrestLogo(
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_icon ?? undefined
    );
    setrestMenu(
      getSingleRestaurant?.data?.data?.data[0]?.menu?.map(
        (item) => item.menu_pdf
      )
    );

    const fetchData = async () => {
      try {
        const dataArrays = getSingleRestaurant?.data?.data?.data.find(
          (d) => d.detailData
        )?.detailData;

        // Assuming features is an array inside each restaurant object
        const newArray =
          dataArrays?.map((restaurant) => ({
            id: restaurant.restaurant_detail_id,
            name: restaurant.heading,
            tagImage: restaurant.image_path,
            tagHeading: restaurant.heading,
            tagDescription: restaurant.description,
            detailImgAltTag: restaurant.img_alt_tag,
            tagFeatures:
              restaurant.features && restaurant.features.map((f) => f.features), //condition
          })) || [];

        // Retrieve previous data from sessionStorage
        const storedDataArray = sessionStorage.getItem("dataArray");
        if (storedDataArray) {
          // Filter out already existing data from fetched data
          const filteredNewArray = newArray.filter(
            (item) =>
              !JSON.parse(storedDataArray).find(
                (existingItem) => existingItem.id === item.id
              )
          );
          // Merge previous data with newly fetched data
          const mergedArray =
            JSON.parse(storedDataArray).concat(filteredNewArray);
          setRestaurandDetailDataArray(mergedArray);
        } else {
          // Set the fetched data if no previous data exists
          setRestaurandDetailDataArray(newArray);
        }
      } catch (error) {}
    };

    fetchData();
  }, [getSingleRestaurant?.data]);

  // Save dataArray to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(
      "dataArray",
      JSON.stringify(restaurandDetailDataArray)
    );
  }, [restaurandDetailDataArray]);

  // Retrieve dataArray from sessionStorage on component mount
  useEffect(() => {
    const storedDataArray = sessionStorage.getItem("dataArray");
    if (storedDataArray) {
      setRestaurandDetailDataArray(JSON.parse(storedDataArray));
    }
  }, []);

  //gettting menu data
  const menudata = getSingleRestaurant?.data?.data?.data[0]?.menu?.map(
    (item) => item?.menu_name
  );
  const [initialValues, setInitialValues] = useState({
    RestaurantName:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_name || "",
    slugName: getSingleRestaurant?.data?.data?.data[0]?.slug || "",
    metaTitle: getSingleRestaurant?.data?.data?.data[0]?.meta_title || "",
    keyWords: getSingleRestaurant?.data?.data?.data[0]?.keywords || "",
    metaDescription:
      getSingleRestaurant?.data?.data?.data[0]?.meta_description || "",
    RestaurantDescription:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_description || "",
    RestaurantTypeId:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id || "",
    RestaurantTagLine:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline || "",
    imageAltTag: getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag || "",
    innerBannerImgTag:
      getSingleRestaurant?.data?.data?.data[0]?.inner_banner_alt_tag || "",
    highlightsDescription:
      getSingleRestaurant?.data?.data?.data[0]?.highligts_description || "",
    highlightsTitle:
      getSingleRestaurant?.data?.data?.data[0]?.highligts_title || "",
    RestaurantTags:
      getSingleRestaurant?.data?.data?.data[0]?.tags?.map(
        (tag) => tag.restaurant_tag
      ) || [],
    Menus:
      getSingleRestaurant?.data?.data?.data[0]?.menu?.map(
        (item) => item?.menu_name
      ) || [],
    dataArray: restaurandDetailDataArray,
    IMAGEALTTAG: getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag || "",
    chefSignatureImageTag:
      getSingleRestaurant?.data?.data?.data[0]?.chef_signature_alt_tag || "",
    leftImageTag:
      getSingleRestaurant?.data?.data?.data[0]?.left_image_alt_tag || "",
    restayrantLogoImageTag:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_logo_alt_tag || "",
    rightImageTag:
      getSingleRestaurant?.data?.data?.data[0]?.right_image_alt_tag || "",
      ambienceTitle:getSingleRestaurant?.data?.data?.data[0]?.ambience_title || "",
      ambienceDescription:getSingleRestaurant?.data?.data?.data[0]?.ambience_description || "",
    // detailData
    // detailImgAltTag: getSingleRestaurant?.data?.data?.data[0]?.detailData?.map(
    //   (tag) => tag.img_alt_tag
    // ) || [],
  });

  // const convertImageToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader(); reader.onloadend = () => {
  //       const base64String = reader.result.split(',')[1];
  //       resolve(base64String);
  //     }; reader.onerror = (error) => {
  //       reject(error);
  //     }; reader.readAsDataURL(file);
  //   });
  // };
console.log("getSingleRestaurant",getSingleRestaurant)
  useEffect(() => {
    setValues({ ...initialValues });

    const originalUrl = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.restaurant_image
    )?.restaurant_image;

    setmainCroppedImage(originalUrl);
    const originalUrl2 = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.restaurant_menu_image
    )?.restaurant_menu_image;
    if (originalUrl2 === "null") {
      setmainCroppedSecImage(null);
    } else {
      setmainCroppedSecImage(originalUrl2);
    }

    const originalUrl3 = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.restaurant_icon
    )?.restaurant_icon;
    if (originalUrl3 === "null") {
      setMainCroppedIcon(null);
    } else {
      setMainCroppedIcon(originalUrl3);
    }

    const bannerImageUrl = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.inner_banner_image
    )?.inner_banner_image;
    setMainCroppedBannerImage(bannerImageUrl);

    const detailImageObject = (
      getSingleRestaurant?.data?.data?.data[0]?.detailData || []
    ).reduce((accumulator, data, index) => {
      accumulator[`section_${data.restaurant_detail_id}`] = data.image_path;
      return accumulator;
    }, {});

    setImage3(detailImageObject);
  }, [getSingleRestaurant?.data]);

  const hasDecimalPoint = (number) => {
    return number % 1 !== 0;
  };
  let base64data = "";

  const RestaurantNameRef = useRef(null);
  const highlightsDescriptionRef = useRef(null);
  const highlightsTitleRef = useRef(null);
  const RestaurantTagLineRef = useRef(null);
  const RestaurantDescriptionRef = useRef(null);
  const ImageRef = useRef(null);
  const BannerImageRef = useRef(null);
  const slugRef = useRef(null);

  const handleRestaurantNameChange = (event) => {
    const value = event.target.value;

    setFieldValue("RestaurantName", value);
    // setFieldValue("slugName", value.toLowerCase().replace(/\s+/g, "-"));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };
  const [typeForImgAltTag, setTypeForImgAltTag] = useState("");
  const [altTags, setAltTags] = useState({
    IMAGEALTTAG: "",
    chefSignatureImageTag: "",
    leftImageTag: "",
    restayrantLogoImageTag: "",
    rightImageTag: "",
  });

  const [load, setload] = useState(true);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RestaurantSchema,
    onSubmit: async (values, action) => {
      console.log("loggggggggggggggg");
      const formData = new FormData();
      formData.append(
        "restaurantTypeId",
        values.RestaurantTypeId === "---Select---"
          ? ""
          : values.RestaurantTypeId
      );
      formData.append("restaurantDescription", values.RestaurantDescription);
      formData.append("restaurantName", values.RestaurantName);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      // formData.append("restaurantTagline", values.RestaurantTagLine)
      formData.append("restaurantTagline", values.RestaurantTagLine);
      formData.append("imageAltTag", altTags.IMAGEALTTAG);
      formData.append("highlightsTitle", values.highlightsTitle);
      formData.append("highlightsDescription", values.highlightsDescription);
      if (extraFields.includes("innerBannerImage")) {
        formData.append("innerBannerImgTag", values.innerBannerImgTag); //condition
      }
      if (extraFields.includes("ambienceTitle")) {
        formData.append("ambienceTitle", values.ambienceTitle);
     }
     formData.append("ambienceDescription", values.ambienceDescription);

      if (values.dataArray) {
        values.dataArray.map((data, index) => {
          let checkUpdate = false;
          if (data.tagImage.length > 500) {
            checkUpdate = true;
          }

          const imgKey = Object.keys(image3);
          let fileKey = "";
          if (maincroppedThirdFile !== null) {
            fileKey = Object.keys(maincroppedThirdFile);
          }

          let resultArray = [];
          let matchCount = 0;
          let nonMatchCount = 0;

          for (const element of imgKey) {
            if (fileKey.includes(element)) {
              matchCount++;
              resultArray.push({ [`${element}`]: true });
            } else {
              nonMatchCount++;
              resultArray.push({ [`${element}`]: false });
            }
          }

          const filteredArray = resultArray.filter(
            (item) => Object.values(item)[0] === true
          );

          const isNewSectionImageTrue = filteredArray.some(
            (item) =>
              Object.keys(item)[0] === `section_${data.id}` &&
              Object.values(item)[0] === true
          );
          //base64
          // convertImageToBase64(image3)
          if (extraFields.includes("tagFeatures")) {
            const detailFeatures = Object.values(data.tagFeatures); //condition

            const elementData = {
              id: hasDecimalPoint(data.id) ? null : data.id,
              heading: data.name,
              detailImages: data.tagImage,
              description: data.tagDescription,
              detailImgAltTag: data.detailImgAltTag,
              features: detailFeatures, //condition
              hasNewDetailImages: checkUpdate,
            };

            formData.append(
              `restaurantDetail[${index}]`,
              JSON.stringify(elementData)
            );
          } else {
            const elementData = {
              id: hasDecimalPoint(data.id) ? null : data.id,
              heading: data.name,
              detailImages: data.tagImage,
              description: data.tagDescription,
              detailImgAltTag: data.detailImgAltTag,
              hasNewDetailImages: checkUpdate,
            };

            formData.append(
              `restaurantDetail[${index}]`,
              JSON.stringify(elementData)
            );
          }
        });
      }
      if (values.RestaurantTags) {
        const restaurantArray = Object.values(values.RestaurantTags);

        restaurantArray.map((restTags, index) => {
          formData.append(`restaurantTags[${index}]`, restTags);
        });
      }
      if (values.Menus) {
        let flag = true;
        const menuArray = Object.values(values.Menus);
        menuArray.map((menuTags, index) => {
          const trimmedTag = menuTags.trim();
          if (trimmedTag !== "") {
            formData.append(`menuTags[${index}]`, menuTags);
          }
        });
        let OldData = [];

        restMenu?.map((item) => {
          if (item !== "") {
            if (typeof item !== "string") {
              formData.append(`menu`, item);
            } else {
              OldData.push(item?.split("images/")[1]);
            }
          } else {
            toast.dismiss();
            toast.error("please upload menu item ");
            flag = false;
            return;
          }
        });
        if (OldData.length > 0) {
          formData.append("oldMenuData", OldData);
        }
        for (let i = 0; i < menuArray?.length; i++) {
          if (restMenu.length > 0) {
            if (menuArray[i] === "") {
              toast.dismiss();
              toast.error("please upload menu title ");
              flag = false;
              return;
            }
          }
        }
        if (flag === false) {
          return;
        }
      }

      if (values?.dataArray?.length !== Object.values(image3)?.length) {
        toast.dismiss();
        toast.error("Please upload all tag images ");
        return;
      }

      if (maincroppedThirdFile) {
        const detailImagesArrayFromObject = Object.values(maincroppedThirdFile);
        detailImagesArrayFromObject.map((detailImage, index) => {
          formData.append(`detailImages`, detailImage);
          // const altTag = initialValues.detailImgAltTag[index];
          // const altTagValue = altTag !== undefined ? altTag : ""; // If altTag is undefined, use ""
          // formData.append(`detailImgAltTag[${index}]`, altTagValue);
        });
      }

      if (maincroppedSecFile) {
        formData.append("menuImage", maincroppedSecFile);
      } else {
        if (maincroppedSecImage === undefined) {
          formData.append("menuImage", null);
        } else {
          formData.append("menuImage", maincroppedSecImage);
        }
      }

      // if (mainCroppedIconFile) {
      //   formData.append("restaurantIcon", mainCroppedIconFile);
      // } else {
      //   if (mainCroppedIcon !== null) {
      //     formData.append("restaurantIcon", mainCroppedIcon);
      //   } else {
      //     formData.append("restaurantIcon", null);
      //   }
      // }

      if (maincroppedFile || maincroppedImage) {
        formData.append("restaurantImage", maincroppedFile);
      } else {
        toast.dismiss();
        if (ImageRef.current) {
          ImageRef.current.focus();
        }
        toast.error("Please upload Image");
        return;
      }

      console.log("mainCroppedBannerImageFile", mainCroppedBannerImageFile);
      console.log("maincroppedBannerImage", maincroppedBannerImage);

      if (extraFields.includes("innerBannerImage")) {
        if (mainCroppedBannerImageFile || maincroppedBannerImage) {
          //condition
          formData.append("innerBannerImage", mainCroppedBannerImageFile);
        } else {
          if (BannerImageRef.current) {
            BannerImageRef.current.focus();
          }
          toast.dismiss();
          toast.error("Please upload inner banner image");
          return;
        }
      }
      if (extraFields.includes("leftImage")) {
        if (leftImage) {
          formData.append("leftImage", leftImage);
          formData.append("leftImageAltTag", altTags.leftImageTag);
        } else {
          toast.dismiss();
          toast.error("please upload left image ");
          return;
        }
      }
      if (extraFields.includes("rightImage")) {
        if (rightImage) {
          formData.append("rightImage", rightImage);
          formData.append("rightImageAltTag", altTags.rightImageTag);
        } else {
          toast.dismiss();
          toast.error("please upload right image ");
          return;
        }
      }

      if (restLogo) {
        formData.append("restaurantIcon", restLogo);
        formData.append("restaurantIconAltTag", altTags.restayrantLogoImageTag);
      }
      if (extraFields.includes("chefSignatue")) {
        if (chefSignatue) {
          formData.append("chefSignature", chefSignatue);
          formData.append("chefSignatureAltTag", altTags.chefSignatureImageTag);
        } else {
          toast.dismiss();
          toast.error("please upload chef signature  ");
          return;
        }
      }
      if (actions === "PREVIEW") {
        preview.mutate({ id, formData });
      }
      console.log("hit");
      if (actions === "SAVE") {
        if (id > 0) {
          editRestaurantMutation.mutate({ id, formData });
        }
      }
    },
    // validateOnBlur: false,
    // validateOnChange: false,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
    validateOnBlur,
    validateOnChange,
  } = formik;

  // useEffect(() => {
  //   if (Object.values(errors).some((error) => error !== undefined)) {
  //     const firstEmptyField = Object.entries(errors).find(
  //       ([, error]) => error !== undefined && !touched[error.path]
  //     );

  //     if (firstEmptyField) {
  //       const [name] = firstEmptyField;
  //       switch (name) {
  //         case "RestaurantName":
  //           RestaurantNameRef.current.focus();
  //           break;
  //         case "RestaurantTagLine":
  //           RestaurantTagLineRef.current.focus();
  //           break;
  //         case "RestaurantDescription":
  //           RestaurantDescriptionRef.current.focus();
  //           break;
  //         case "highlightsTitle":
  //           if (extraFields.includes("highlightsTitle"))
  //             highlightsTitleRef.current.focus();
  //         case "highlightsDescription":
  //           if (extraFields.includes("highlightsDescription"))
  //             highlightsDescriptionRef.current.focus();
  //         case "slugName":
  //           slugRef.current.focus();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }
  // }, [errors]);

  const handelFocus=()=>{
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;
        switch (name) {
          case "RestaurantName":
            RestaurantNameRef.current.focus();
            break;
          case "RestaurantTagLine":
            RestaurantTagLineRef.current.focus();
            break;
          case "RestaurantDescription":
            RestaurantDescriptionRef.current.focus();
            break;
          case "highlightsTitle":
            if (extraFields.includes("highlightsTitle"))
              highlightsTitleRef.current.focus();
          case "highlightsDescription":
            if (extraFields.includes("highlightsDescription"))
              highlightsDescriptionRef.current.focus();
          case "slugName":
            slugRef.current.focus();
            break;
          default:
            break;
        }
      }
    }
    handleSubmit();
  }

  useEffect(() => {
    setFieldValue("RestaurantName", initialValues?.RestaurantName);
    setFieldValue(
      "RestaurantDescription",
      initialValues?.RestaurantDescription
    );

    formik.resetForm({
      values: {
        ...formik.values,
        dataArray: values?.dataArray,
        RestaurantName:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_name,
        slugName: getSingleRestaurant?.data?.data?.data[0]?.slug,
        metaTitle: getSingleRestaurant?.data?.data?.data[0]?.meta_title || "",
        keyWords: getSingleRestaurant?.data?.data?.data[0]?.keywords || "",
        metaDescription:
          getSingleRestaurant?.data?.data?.data[0]?.meta_description || "",

        RestaurantDescription:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_description,
        RestaurantTypeId:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id,
        RestaurantTagLine:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline,
        RestaurantTagLine:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline,
        imageAltTag: getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag,
        innerBannerImgTag:
          getSingleRestaurant?.data?.data?.data[0]?.inner_banner_alt_tag,
        highlightsDescription:
          getSingleRestaurant?.data?.data?.data[0]?.highligts_description || "",
        highlightsTitle:
          getSingleRestaurant?.data?.data?.data[0]?.highligts_title || "",
        Menus:
          getSingleRestaurant?.data?.data?.data[0]?.menu?.map(
            (item) => item?.menu_name
          ) || [],
        RestaurantTags:
          // getSingleRestaurant?.data?.data?.data[0]?.RestaurantTypeId ||
          getSingleRestaurant?.data?.data?.data[0]?.tags?.map(
            (tag) => tag.restaurant_tag
          ) || [],
ambienceTitle:getSingleRestaurant?.data?.data?.data[0]?.ambience_title || "",
      ambienceDescription:getSingleRestaurant?.data?.data?.data[0]?.ambience_description || "",
        // detailImgAltTag: getSingleRestaurant?.data?.data?.data[0]?.detailData?.map(
        //   (alt) => alt.img_alt_tag
        // ) || [],
      },
    });
  }, [getSingleRestaurant.data]);

  //form update
  const preview = useMutation(querydata.previewRestaurantedit, {
    onError: (error) => {
      // toast.dismiss();
      // toast.error(error.message);
    },
    onSuccess: (data) => {
      const websiteUrl = `${data?.websiteUrl}?previewdining=true&uid=${data?.encryptedUserId}`;
      window.open(websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });

  const editRestaurantMutation = useMutation(querydata.updateRestaurant, {
    onError: (error) => {
      console.log("errorrrrrrrrr");
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);

      queryClient.resetQueries("getAllRestaurents");
      queryClient.invalidateQueries(["Restaurant", id])
      navigate("/manage-dining");
      setmainCroppedImage(null);
      setMainCroppedBannerImage(null);
      setImage(null);
      setBannerImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (getSingleRestaurant.data) {
        const dataArrays = getSingleRestaurant.data.data.data.find(
          (d) => d.detailData
        )?.detailData;
        setload(false);
        const dataArray = dataArrays?.map((restaurant) => {
          return {
            id: restaurant.restaurant_detail_id,
            name: restaurant.heading,
            tagImage: restaurant.image_path,
            tagHeading: restaurant.heading,
            tagDescription: restaurant.description,
            tagFeatures: restaurant.features.map((f) => f.features),
            detailImgAltTag: restaurant.img_alt_tag,
          };
        });

        setInitialValues((prevValues) => ({
          // ...prevValues,
          dataArray: dataArray,
        }));

        setAltTags({
          IMAGEALTTAG:
            getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag || "",
          chefSignatureImageTag:
            getSingleRestaurant?.data?.data?.data[0]?.chef_signature_alt_tag ||
            "",
          leftImageTag:
            getSingleRestaurant?.data?.data?.data[0]?.left_image_alt_tag || "",
          restayrantLogoImageTag:
            getSingleRestaurant?.data?.data?.data[0]?.restaurant_logo_alt_tag ||
            "",
          rightImageTag:
            getSingleRestaurant?.data?.data?.data[0]?.right_image_alt_tag || "",
        });
        if (getSingleRestaurant?.data?.data?.data) {
          const restaurantData = getSingleRestaurant.data.data.data[0];

          setInitialValues((prevValues) => ({
            // ...prevValues,
            dataArray: dataArray,
            RestaurantName:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_name,
            slugName: getSingleRestaurant?.data?.data?.data[0]?.slug,
            metaTitle:
              getSingleRestaurant?.data?.data?.data[0]?.meta_title || "",
            keyWords: getSingleRestaurant?.data?.data?.data[0]?.keywords || "",
            metaDescription:
              getSingleRestaurant?.data?.data?.data[0]?.meta_description || "",
            imageAltTag:
              getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag || "",
            innerBannerImgTag:
              getSingleRestaurant?.data?.data?.data[0]?.inner_banner_alt_tag ||
              "",
            RestaurantDescription:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_description,
            RestaurantTypeId:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id ||
              prevValues.RestaurantTypeId,
            RestaurantTagLine:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline ||
              prevValues.RestaurantTagLine,
            RestaurantTags:
              getSingleRestaurant?.data?.data?.data[0]?.RestaurantTypeId ||
              getSingleRestaurant?.data?.data?.data[0]?.tags?.map(
                (tag) => tag.restaurant_tag
              ) ||
              prevValues.RestaurantTags,
            RestaurantTagLine:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline ||
              prevValues.RestaurantTagLine,
              ambienceTitle:getSingleRestaurant?.data?.data?.data[0]?.ambience_title || "",
      ambienceDescription:getSingleRestaurant?.data?.data?.data[0]?.ambience_description || "",
            // IMAGEALTTAG: getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag || '',
            // chefSignatureImageTag: getSingleRestaurant?.data?.data?.data[0]?.chef_signature_alt_tag || '',
            // leftImageTag: getSingleRestaurant?.data?.data?.data[0]?.left_image_alt_tag || '',
            // restayrantLogoImageTag: getSingleRestaurant?.data?.data?.data[0]?.restaurant_logo_alt_tag || '',
            // rightImageTag: getSingleRestaurant?.data?.data?.data[0]?.right_image_alt_tag || ''
            // detailImgAltTag:getSingleRestaurant?.data?.data?.data[0]?.detailData?.map(
            //   (tag) => tag.img_alt_tag
            // ) ||
            // [],
          }));
        }

        formik.resetForm({
          values: {
            ...formik.values,
            dataArray: restaurandDetailDataArray,
            // IMAGEALTTAG: getSingleRestaurant?.data?.data?.data[0]?.image_alt_tag || '',
            // chefSignatureImageTag: getSingleRestaurant?.data?.data?.data[0]?.chef_signature_alt_tag || '',
            // leftImageTag: getSingleRestaurant?.data?.data?.data[0]?.left_image_alt_tag || '',
            // restayrantLogoImageTag: getSingleRestaurant?.data?.data?.data[0]?.restaurant_logo_alt_tag || '',
            // rightImageTag: getSingleRestaurant?.data?.data?.data[0]?.right_image_alt_tag || '',
            // RestaurantName:
            //   getSingleRestaurant?.data?.data?.data[0]?.restaurant_name,
            // RestaurantDescription:
            //   getSingleRestaurant?.data?.data?.data[0]?.restaurant_description
            //   ,
      //       ambienceTitle:getSingleRestaurant?.data?.data?.data[0]?.ambience_title || "",
      // ambienceDescription:getSingleRestaurant?.data?.data?.data[0]?.ambience_description || "",
            RestaurantTagLine:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline,
            RestaurantTypeId:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id,
            RestaurantTags: getSingleRestaurant?.data?.data?.data[0].tags?.map(
              (tag) => tag.restaurant_tag
            ),
          },
        });
      }
    };

    fetchData();
  }, [restaurandDetailDataArray, formik.resetForm]);

  const [showOfferImgTagModal, setShowOfferImgTagModal] = useState(false);
  const [showInnerBannerModal, setShowInnerBannerModal] = useState(false);

  const handleToggleOfferImgModal = (type) => {
    setTypeForImgAltTag(type);
    setShowOfferImgTagModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    setAltTags((prevAltTags) => ({
      ...prevAltTags,
      [tagType]: value,
    }));
    setShowOfferImgTagModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  const handleCloseOfferImgTagModal = () => {
    setShowOfferImgTagModal(false);
  };

  // Function to close Inner Banner modal
  const handleCloseInnerBannerModal = () => {
    setShowInnerBannerModal(false);
  };

  const handleToggleInnerBannerModal = () => {
    setShowInnerBannerModal((prevState) => !prevState);
  };

  const handleSaveInnerBannerTag = (tagType, value) => {
    // Save the inner banner tag value to state
    setFieldValue("innerBannerImgTag", value);
    setShowInnerBannerModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  if (!getSingleRestaurant.data) {
    <div>Loading...</div>;
  }

  const handerror = () => {
    if (
      errors?.dataArray ||
      // errors?.RestaurantName ||
      // errors?.RestaurantDescription ||
      errors.RestaurantTypeId
    ) {
      console.log("errors", errors);
      toast.dismiss();
      toast.error("Fill all Required Fields ");
    }
  };


  const [showResortModal, setShowResortModal] = useState(false);
  const handleShowResortModal = () => {
    setShowResortModal(true);
  };
  const handleCloseResortTypeModal = () => {
    setShowResortModal(false);
    setEditrestId(null)

  };
  const [editRestaurantId, setEditrestId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleEdit = (restaurantTypeId) => {
    console.log("restaurantTypeId", restaurantTypeId)
    if (restaurantTypeId) {
      setEditrestId(restaurantTypeId)
      setShowResortModal(true)
    }

  };


  const handleInvalidateRestaurantTypes = () => {
    // Invalidate the query to refetch restaurant types
    setEditrestId(null)
    queryClient.invalidateQueries('getAllRestaurantTypes');
  };
  const handleDelete = (rowData) => {
   
    setSelectedRowData(rowData.value);
    setSelectedRowNameData(rowData.label);
    setShowDeleteModal(true);
   
  };

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-dining">
                    Manage Dining
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Restaurant
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-item-center">
              <Link
                to="/manage-dining"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Edit Restaurant</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Restaurant</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Image">
                            <div className="d-flex align-items-center">
                              <Form.Label className="mb-0">
                                Image <span className="mandatory">*</span>
                              </Form.Label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleOfferImgModal("IMAGEALTTAG")
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!maincroppedImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "firstImage")
                                  }
                                  ref={ImageRef}
                                  tabIndex={-1}
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleRemoveImage(e, "maincroppedImage")
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <p className="form-error text-danger">
                              {maincroppedImage ? "" : "Please enter image "}
                            </p> */}
                          </Form.Group>
                        </Col>
                        {extraFields.includes("innerBannerImage") && (
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Inner Banner Image{" "}
                                  <span className="mandatory">*</span>
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={(e) =>
                                    handleToggleInnerBannerModal(
                                      "INNERBANNERIMGTAG"
                                    )
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!maincroppedBannerImage && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    for="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "BannerImage")
                                    }
                                    ref={BannerImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {maincroppedBannerImage && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={maincroppedBannerImage}
                                      target="_blank"
                                    >
                                      <img
                                        src={maincroppedBannerImage}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleBannerImage(e, image)
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* <p className="form-error text-danger">
                              {maincroppedImage ? "" : "Please enter image "}
                            </p> */}
                            </Form.Group>
                          </Col>
                        )}
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="Restaurant Name"
                          >
                            <Form.Label>
                              Restaurant Name{" "}
                              <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              ref={RestaurantNameRef}
                              type="text"
                              className="py-3 shadow-none px-4"
                              value={values.RestaurantName}
                              onChange={
                                handleRestaurantNameChange
                                // (e) =>
                                //   setFieldValue(
                                //     "RestaurantName",
                                //     e.target.value
                                //   )

                                // setFieldValue("RestaurantName", e.target.value)
                              }
                              name="RestaurantName"
                              maxLength={50}
                            />
                            {errors.RestaurantName && touched.RestaurantName ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantName}
                              </p>
                            ) : error.RestaurantName ? (
                              <p className="form-error text-danger">
                                {error.RestaurantName}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="Restaurant Type"
                          >
                            <Form.Label>
                              Restaurant Type{" "}
                              {/* <span className="mandatory">*</span> */}
                            </Form.Label>
                            <RestaurantSelect
                              AllRestaurantTypeId={AllRestaurantTypeId}
                              values={values}
                              //  handleBlur={handleBlur}
                              handleEdit={handleEdit} // Pass the edit function
                              handleDelete={handleDelete} // Pass the delete function
                              handleChange={formik.handleChange} // Pass Formik handleChange
                              handleBlur={formik.handleBlur} // Pass Formik handleBlur
                              setFieldValue={formik.setFieldValue}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={2} className="mt-0 mb-0">

                          <Form.Group
                            // className="mb-3"
                            controlId="exampleForm.ControlInput1"
                            className="py-3 shadow-none px-4 "
                          >
                            <div className="mt-4">
                              <Button variant="primary" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" onClick={handleShowResortModal}>
                                <FontAwesomeIcon icon={faPlus} /> Add
                              </Button>
                            </div>

                          </Form.Group>


                        </Col>
                        {/* <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="Restaurant Type"
                          >
                            <Form.Label>
                              Restaurant Type{" "}
                           
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="py-3 shadow-none px-4"
                              onChange={(e) =>
                                setFieldValue(
                                  "RestaurantTypeId",
                                  e.target.value
                                )
                              }
                              value={values.RestaurantTypeId}
                              name="RestaurantTypeId"
                            >
                              <option value="0">---Select---</option>
                              {AllRestaurantTypeId.data &&
                                AllRestaurantTypeId.data.data.map((option) => (
                                  <option
                                    key={option.restaurant_type_id}
                                    value={option.restaurant_type_id}
                                  >
                                    {option.restaurant_type}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.RestaurantTypeId &&
                            touched.RestaurantTypeId ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTypeId}
                              </p>
                            ) : error.RestaurantTypeId ? (
                              <p className="form-error text-danger">
                                {error.RestaurantTypeId}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col> */}
                        {extraFields.includes("restLogo") && (
                          <Col lg={12}>
                            <Form.Group className="mb-3" controlId="Rest Logo">
                              <div className="d-flex align-items-center">
                                <Form.Label className="mb-0">
                                  Restaurant Logo <span className=""></span>{" "}
                                </Form.Label>
                                <a
                                  title="Add image alt tag"
                                  className="btn image-tag"
                                  onClick={() =>
                                    handleToggleOfferImgModal(
                                      "restayrantLogoImageTag"
                                    )
                                  }
                                >
                                  <HiPlus />
                                </a>
                              </div>
                              {!restLogo && (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal("", "restLogo")
                                    }
                                    // ref={ImageRef}
                                    tabIndex={-1}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {restLogo && (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={
                                        restLogo?.type === "image/png"
                                          ? URL.createObjectURL(restLogo)
                                          : restLogo
                                      }
                                      target="_blank"
                                    >
                                      <img
                                        src={
                                          restLogo?.type === "image/png"
                                            ? URL.createObjectURL(restLogo)
                                            : restLogo
                                        }
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleRemoveImage(e, "RestaurantLogo")
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="TagLine">
                            <Form.Label>
                              Tag Line <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              ref={RestaurantTagLineRef}
                              as="textarea"
                              style={{ height: "100px" }}
                              value={values.RestaurantTagLine}
                              onChange={(e) =>
                                // test2(e)
                                setFieldValue(
                                  "RestaurantTagLine",
                                  e.target.value
                                )
                              }
                              name="RestaurantTagLine"
                            />
                            {errors.RestaurantTagLine &&
                            touched.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTagLine}
                              </p>
                            ) : error.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {error.RestaurantTagLine}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Description">
                            <Form.Label>
                              Description <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              ref={RestaurantDescriptionRef}
                              as="textarea"
                              style={{ height: "100px" }}
                              value={values.RestaurantDescription}
                              onChange={(e) =>
                                // test2(e)
                                setFieldValue(
                                  "RestaurantDescription",
                                  e.target.value
                                )
                              }
                              name="RestaurantDescription"
                            />
                            {errors.RestaurantDescription &&
                            touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantDescription}
                              </p>
                            ) : error.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {error.RestaurantDescription}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Rest Tag">
                            <Form.Label>Restaurant Tags</Form.Label>

                            <RestaurantTag2
                              setFieldValue={setFieldValue}
                              name={getFieldProps("RestaurantTags").name}
                              value={getFieldProps("RestaurantTags").value}
                              maxLength={20}
                              initialValues={initialValues}
                              values={values}
                              formik={formik}
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Restaurant Icon</Form.Label>
                            {!mainCroppedIcon && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "restaurantIcon")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {mainCroppedIcon && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={mainCroppedIcon} target="_blank">
                                    <img
                                      src={mainCroppedIcon}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleRemoveIcon(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}
                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Show Menu/Activities</Form.Label>
                            {!maincroppedSecImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "secondImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose menu from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedSecImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={
                                    maincroppedSecImage.endsWith(".pdf")
                                      ? {
                                        // width: "68%",
                                        height: "68%",
                                      }
                                      : { width: "100%", height: "100%" }
                                  }
                                >
                                  <a
                                    href={
                                      maincroppedSecImage.endsWith(".pdf")
                                        ? maincroppedSecImage
                                        : maincroppedSecImage
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      // src={maincroppedSecImage}
                                      src={
                                        maincroppedSecImage.endsWith(".pdf")
                                          ? pdfImg
                                          : maincroppedSecImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleSecRemoveImage(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3 mt-4 mb-4"
                            controlId="Menus"
                          >
                            <Form.Label>Menus</Form.Label>

                            <RestaurentTags
                              showCropMediaModal={showCropMediaModal}
                              setFieldValue={setFieldValue}
                              name={getFieldProps("Menus").name}
                              value={getFieldProps("Menus").value}
                              maxLength={20}
                              initialValues={initialValues}
                              values={values}
                              formik={formik}
                              restMenu={restMenu}
                              setrestMenu={setrestMenu}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {extraFields.includes("highlightsTitle") && (
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="slugName">
                            <Form.Label>
                              Highlights Title{" "}
                              <span className="mandatory">*</span>
                            </Form.Label>
                            <div className="bs-example">
                              <input
                                ref={highlightsTitleRef}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                maxLength={100}
                                name="highlightsTitle"
                                value={values?.highlightsTitle}
                                id="slugName"
                                className="form-control py-3 shadow-none px-4"
                                // onKeyPress={handleKeyPress}
                                data-role="tagsinput"
                              />
                            </div>

                            {errors.highlightsTitle &&
                            touched.highlightsTitle ? (
                              <p className="form-error text-danger">
                                {errors.highlightsTitle}
                              </p>
                            ) : error.highlightsTitle ? (
                              <p className="form-error text-danger">
                                {error.highlightsTitle}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}
                      {extraFields.includes("highlightsDescription") && (
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="highlightsDescription"
                          >
                            <Form.Label>
                              Highlights Description{" "}
                              <span className="mandatory">*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              onChange={handleChange}
                              ref={highlightsDescriptionRef}
                              value={values?.highlightsDescription}
                              name="highlightsDescription"
                              onBlur={handleBlur}
                              maxLength={290}
                            />
                            {errors.highlightsDescription &&
                            touched.highlightsDescription ? (
                              <p className="form-error text-danger">
                                {errors.highlightsDescription}
                              </p>
                            ) : error.highlightsDescription ? (
                              <p className="form-error text-danger">
                                {error.highlightsDescription}
                              </p>
                            ) : !errors.highlightsDescription &&
                              error.highlightsDescription &&
                              touched.highlightsDescription ? (
                              <p className="form-error text-danger">
                                Please select a restaurant description
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}
                      {extraFields.includes("chefSignatue") && (
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="Chief Signature"
                          >
                            <div className="d-flex align-items-center">
                              <Form.Label className="mb-0">
                                Chef Signature{" "}
                                <span className="mandatory">*</span>{" "}
                              </Form.Label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleOfferImgModal(
                                    "chefSignatureImageTag"
                                  )
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!chefSignatue && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "chefSignature")
                                  }
                                  // ref={ImageRef}
                                  tabIndex={-1}
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {chefSignatue && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a
                                    href={
                                      chefSignatue?.type === "image/png"
                                        ? URL.createObjectURL(chefSignatue)
                                        : chefSignatue
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        chefSignatue?.type === "image/png"
                                          ? URL.createObjectURL(chefSignatue)
                                          : chefSignatue
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleRemoveImage(e, "chefSignature")
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      )}
                      {extraFields.includes("leftImage") && (
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Left Image">
                            <div className="d-flex align-items-center">
                              <Form.Label className="mb-0">
                                Left Image <span className="mandatory">*</span>{" "}
                              </Form.Label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleOfferImgModal("leftImageTag")
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!leftImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "leftImage")
                                  }
                                  ref={ImageRef}
                                  tabIndex={-1}
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {leftImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a
                                    href={
                                      leftImage?.type === "image/png"
                                        ? URL.createObjectURL(leftImage)
                                        : leftImage
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        leftImage?.type === "image/png"
                                          ? URL.createObjectURL(leftImage)
                                          : leftImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleRemoveImage(e, "leftImage")
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      )}
                      {extraFields.includes("rightImage") && (
                        <Col lg={12}>
                          <Form.Group className="mb-3" controlId="Right Image">
                            <div className="d-flex align-items-center">
                              <Form.Label className="mb-0">
                                Right Image <span className="mandatory">*</span>{" "}
                              </Form.Label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleOfferImgModal("rightImageTag")
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!rightImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "rightImage")
                                  }
                                  ref={ImageRef}
                                  tabIndex={-1}
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {rightImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a
                                    href={
                                      rightImage?.type === "image/png"
                                        ? URL.createObjectURL(rightImage)
                                        : rightImage
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        rightImage?.type === "image/png"
                                          ? URL.createObjectURL(rightImage)
                                          : rightImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleRemoveImage(e, "rightImage")
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      )}

                      <Col lg={12}>
                        <Form.Group className="mb-3" controlId="slugName">
                          <Form.Label>
                            Slug <span className="mandatory">*</span>
                          </Form.Label>
                          <div className="bs-example">
                            <input
                              ref={slugRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              maxLength={100}
                              name="slugName"
                              value={values?.slugName?.trim()}
                              id="slugName"
                              className="form-control py-3 shadow-none px-4"
                              onKeyPress={handleKeyPress}
                              data-role="tagsinput"
                            />
                          </div>
                          {errors.slugName && touched.slugName ? (
                            <p className="form-error text-danger">
                              {errors.slugName}
                            </p>
                          ) : error.slugName ? (
                            <p className="form-error text-danger">
                              {error.slugName}
                            </p>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Row>
                        <Form.Group className="col-md-12 mb-4">
                          <Form.Label>Keywords</Form.Label>
                          <Form.Control
                            name="keyWords"
                            value={values.keyWords}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            id="Keywords"
                            className="form-control py-3 shadow-none px-4"
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <div className="col-lg-6">
                          <Form.Group className="mb-4">
                            <Form.Label>Meta Title</Form.Label>
                            <Form.Control
                              name="metaTitle"
                              value={values.metaTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="metaTitle"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-4">
                            <Form.Label>Meta Description</Form.Label>
                            <Form.Control
                              name="metaDescription"
                              value={values.metaDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="metaDescription"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={170}
                            />
                          </Form.Group>
                        </div>
                      </Row>
                    </form>
                    <RestaurantDetailEdit
                      setsectionImage={setsectionImage}
                      setImage3={setImage3}
                      formik={formik}
                      handleThirdRemoveImage={handleThirdRemoveImage}
                      handleRemoveImage={handleRemoveImage}
                      showCropMediaModal={showCropMediaModal}
                      image={image}
                      image3={image3}
                      maincroppedThirdImage={maincroppedThirdImage}
                      setRestaurandDetailDataArray={
                        setRestaurandDetailDataArray
                      }
                      setInitialValues={setInitialValues}
                      restaurandDetailDataArray={restaurandDetailDataArray}
                      values={values}
                      initialValues={initialValues}
                      handleChange={handleChange}
                      setValues={setValues}
                      getFieldProps={getFieldProps}
                      setFieldValue={setFieldValue}
                      // handleToggleDetailTagModal={handleToggleDetailTagModal}
                    />
                    <div className="save-btn text-end mt-4">
                      <Link
                        to={`/manage-dining`}
                        value="Cancel"
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-6 py-2 me-2"
                      >
                        Cancel
                      </Link>

                      {bwebsiteId === "TOC" && (
                        <Button
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-6 py-2 me-2"
                          onClick={() => {
                            setAction("PREVIEW");
                            // handleSubmit();
                            handelFocus();
                            handerror();
                          }}
                          disabled={isPreviewLoading}
                        >
                          {isPreviewLoading ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "PREVIEW"
                          )}
                        </Button>
                      )}
                      {/* <input
                        type="submit"
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={() => {
                          handleSubmit();
                          handerror();
                        }}
                        value={isLoading ? <div class="spinner-border text-primary" role="status"></div> : "Update"}
                      /> */}
                      <Button
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={() => {
                          setAction("SAVE");
                          // handleSubmit();
                          handelFocus()
                          handerror();
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          ></div>
                        ) : (
                          "Update"
                        )}
                      </Button>

                      {/* </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumView
              formikEdit={formik}
              image3={image3}
              dataCommingFrom="edit"
              imgArray={imgArray}
              currentFileId={currentFileId}
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedSecImage={setmainCroppedSecImage}
              setmainCroppedSecFile={setmainCroppedSecFile}
              setmainCroppedThirdFile={setmainCroppedThirdFile}
              setmainCroppedThirdImage={setImage3}
              setmainCroppedImage={setmainCroppedImage}
              maincroppedImage={maincroppedImage}
              setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
              setMainCroppedBannerImage={setMainCroppedBannerImage}
              checkValForBannerImage={checkValForBannerImage}
              checkValForSingleImg={checkValForSingleImg}
              checkValForSingleImg3={checkValForSingleImg3}
              setMainCroppedIcon={setMainCroppedIcon}
              setmainCroppedIconFile={setmainCroppedIconFile}
              checkValForIcon={checkValForIcon}
              checkMultipleImageSection={checkMultipleImageSection}
              setmainCroppedFile={setmainCroppedFile}
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              setting_value={settingValue}
              maincroppedThirdFile={maincroppedThirdFile}
              //new code
              indexForMenu={indexForMenu}
              restMenuValue={restMenuValue}
              setrestMenuValue={setrestMenuValue}
              setrestMenu={setrestMenu}
              restMenu={restMenu}
              restLogoValue={restLogoValue}
              setrestLogo={setrestLogo}
              setrestLogoValue={setrestLogoValue}
              //for left image
              setLeftImage={setLeftImage}
              leftImageValue={leftImageValue}
              setLeftImageValue={setLeftImageValue}
              //for right image
              setRightImage={setRightImage}
              rightImageValue={rightImageValue}
              setRightImageValue={setRightImageValue}
              setChefSignatue={setChefSignatue}
              chefSignatueValue={chefSignatueValue}
              setChefSignatueValue={setChefSignatueValue}
              setValForSingleImg={setValForSingleImg}
            />
          )}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showOfferImgTagModal}
        handleCloseImageTagModal={handleCloseOfferImgTagModal}
        initialImageAltTag={values.imageAltTag}
        tagType="IMAGEALTTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="imageAltTag"
      />
      <ImageTagModal
        showModalImageTagModal={showOfferImgTagModal}
        handleCloseImageTagModal={() => setShowOfferImgTagModal(false)}
        initialImageAltTag={altTags[typeForImgAltTag] || ""}
        tagType={typeForImgAltTag}
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="imageAltTag"
      />
      <ImageTagModal
        showModalImageTagModal={showInnerBannerModal}
        handleCloseImageTagModal={handleCloseInnerBannerModal}
        initialImageAltTag={values.innerBannerImgTag}
        tagType="INNERBANNERIMGTAG"
        handleSaveImageAltTag={handleSaveInnerBannerTag}
        inputName="innerBannerImgTag"
      />

      {/* <MultipleImageAltTag
        showModalImageTagModal={showDetailImgTagModal}
        handleCloseImageTagModal={handleCloseDetailImgTagModal}
        // initialImageAltTag=""
        initialImageAltTag={initialValues.detailImgAltTag[detailAltTagId] || ""}
        tagType={detailAltTagId}       
        handleSaveImageAltTag={handleSaveDetailTag}
        inputName={detailAltTagId}
        
      /> */}


      <ResortTypeModal
        show={showResortModal}
        closeModal={handleCloseResortTypeModal}
        values={values}
        editRestaurantId={editRestaurantId}
        AllRestaurantTypeId={AllRestaurantTypeId}
        onInvalidate={handleInvalidateRestaurantTypes}

      />

      <DeleteRestaurantTypeModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        rowData={selectedRowData}
        rowNameData={selectedRowNameData}
        onInvalidate={handleInvalidateRestaurantTypes}

      />

    </>
  );
}
