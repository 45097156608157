import React, { useEffect, useState } from "react";
import { getAllAmenities } from "../../services/service";
import {
  addFeature,
  updateFeature,
  deleteFeature,
} from "../dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AmenityModal from "./AmenityModal";
import { getAllFeatures } from "../dataService/DataService";
import { getSingleFeature } from "../dataService/DataService";

export const amenitySchema = Yup.object({
  amenityName: Yup.string().trim().required("Please enter amenity Name"),
});

function AmenitiesTab({
  setFieldValue,
  showCropMediaModal,
  amenityFileData,
  setAmenityFile,
  amenityCropImage,
  setAmenityCropImage,
}) {
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const [amenityId, setAmenityId] = useState(null);
  const handleClose = (e) => {
    // e.preventDefault();
    setShow(false);
    setAmenityFile("");
    setAmenityCropImage("");
    setValues({
      amenityName: "",
      amenityFromEditIcon: "",
    });
    setModalKey((prevKey) => prevKey + 1);
  };
  const [modalTitle, setModalTitle] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [modalKey, setModalKey] = useState(0);

  const getSigleAmenity = useQuery(["getSigleAmenity", +amenityId], () =>
    getSingleFeature(amenityId)
  );

  let AllAmenities = useQuery("getAllAmenities", getAllFeatures);

  const handleShowAmentieModal = (amenity_id, e, val) => {
    if (e) {
      e.preventDefault();
    }

    // setValues(initialValues);

    setShow(true);
    setAmenityFile("");
    setAmenityCropImage("");
    if (val === "edit") {
      setModalTitle("Edit Feature");
      setModalHeading("Edit");
      setAmenityId(amenity_id);
      setValues(initialValues);
    } else {
      setAmenityId(null);
      setModalTitle("Add New Feature");
      setModalHeading("Add amenity");
      setValues({
        amenityName: "",
        amenityFromEditIcon: "",
      });
    }
  };

  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const handleAmenityChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setCheckBoxArray([...checkBoxArray, value]);
    } else {
      setCheckBoxArray(checkBoxArray.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    setFieldValue("amenitiesId", checkBoxArray);
  }, [checkBoxArray, setFieldValue]);

  const initialValues = {
    amenityName: getSigleAmenity.data?.data[0]?.feature_name || "",
    amenityFromEditIcon: getSigleAmenity?.data?.data[0]?.icon_path || "",
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    resetForm,
    setValues,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: amenitySchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("featureName", values.amenityName);
      if (amenityFileData) {
        formData.append("icon", amenityFileData);
      } else {
        formData.append("icon", values.amenityFromEditIcon);
      }

      if (amenityId > 0) {
        updateAmentiesMutation.mutate({ formData, amenityId });
      } else {
        addAmentiesMutation.mutate(formData);
      }
      resetForm();
    },
  });

  const updateAmentiesMutation = useMutation(updateFeature, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
      console.log("error is  : ", error);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);

      navigate("/manage-venue/create-venue");
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues("");
      setShow(false);
      setAmenityFile("");
      setAmenityCropImage("");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const addAmentiesMutation = useMutation(addFeature, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setShow(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-venue/create-venue");
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues("");
      setAmenityFile("");
      setAmenityCropImage("");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  useEffect(() => {
    if (getSigleAmenity.isSuccess) {
      setValues((prevValues) => ({
        ...prevValues,
        amenityName: getSigleAmenity?.data?.data[0]?.feature_name || "",
        amenityFromEditIcon: getSigleAmenity.data?.data[0]?.icon_path || "",
      }));
    }
  }, [getSigleAmenity?.data, setValues]);

  const handleAmenityDelete = (amenityId, e) => {
    if (e) {
      e.preventDefault();
    }
    amenityDelete.mutate(amenityId);
  };
  const amenityDelete = useMutation(deleteFeature, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAllAmenities");
    },
  });

  return (
    <>
      <div className="edit-mein-info">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>Facilities</h5>
          </div>
          <div className="header-action">
            <a
              onClick={(e) => handleShowAmentieModal(e)}
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
            >
              Add New
            </a>
          </div>
        </div>
        <div className="row mt-4 row-gap-4">
          {AllAmenities.data &&
            AllAmenities.data.data.map((amenity) => (
              <div
                key={amenity.venue_feature_id}
                className="col-xxl-3 col-xl-4 col-lg-6"
              >
                <div className="amenity-wrap d-flex align-items-center mb-3 border px-3 py-3 h-100">
                  <div className="check-amenity">
                    <input
                      type="checkbox"
                      value={amenity.venue_feature_id}
                      onChange={(e) => {
                        handleAmenityChange(e);
                      }}
                      id={amenity.venue_feature_id}
                      name={amenity.venue_feature_id}
                    />
                  </div>

                  <div className="amenity-view d-flex align-items-center ps-2">
                    <div className="icon-amenity">
                      <img src={amenity.icon_path} />
                    </div>
                    <div className="name-amenity px-2">
                      {amenity.feature_name}
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleShowAmentieModal(
                        amenity.venue_feature_id,
                        e,
                        "edit"
                      )
                    }
                    className="btn btn-primary btn-sm btn-theme"
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button
                    variant="info"
                    onClick={(e) =>
                      handleAmenityDelete(amenity.venue_feature_id, e)
                    }
                    className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <AmenityModal
        key={modalKey}
        show={show}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        values={values}
        amenityFileData={amenityFileData}
        amenityCropImage={amenityCropImage}
        showCropMediaModal={showCropMediaModal}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        modalTitle={modalTitle}
        modalHeading={modalHeading}
        
      />
    </>
  );
}

export default AmenitiesTab;
