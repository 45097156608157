import React,{useState,useEffect} from 'react'
import { useMutation, useQueryClient } from "react-query";
import { Button, Form } from 'react-bootstrap';
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { toast } from "react-toastify";
import * as querydata from "../dataService/DataService";
export const headSchema = Yup.object({
    name: Yup.string().trim().required("Please enter restaurant type name"),
})
function ResortTypeModal({show='',closeModal,AllRestaurantTypeId=[],editRestaurantId='',onInvalidate='',handleShowDeleteModal}) {
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const handalCloseModal = ()=>{
        setValues({name:""})
        closeModal(false)
    }
    const options = AllRestaurantTypeId?.data?.data?.map((option) => ({
        value: option.restaurant_type_id,
        label: option.restaurant_type,
      })) || [];

   const restaurantName=   options?.find((opt) => opt.value === editRestaurantId) || null
    const initialValuesForHead = {
        name:  restaurantName?.label ||"",
    }
    let modalHeading;
    if(editRestaurantId!=null && editRestaurantId!=''){
        modalHeading='Edit Restaurant Type Name'
    }else{
        modalHeading='Add Restaurant Type Name'

    }


    
    const {
        values,
        setValues,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setTouched,  
        resetForm    
    } = useFormik({
        initialValues: initialValuesForHead,
        validationSchema: headSchema,
        onSubmit: (values, action) => {


if (editRestaurantId !=null) {
    
    addnewprojectMutation.mutate({editRestaurantId,values});
}else{
    updateProjectMutation.mutate({values});

}

        }
    })

    useEffect(() => {
       setValues(initialValuesForHead);
    }, [editRestaurantId]);
    // updateRestaurantTypes
    // insertRestaurantType
    const addnewprojectMutation = useMutation(querydata.updateRestaurantTypes, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            onInvalidate();
            closeModal(false);
            toast.dismiss();
            toast.success(data.message);
            setValues({name:""})

            resetForm(); // Reset the form values
            setIsLoading(false);         
            // queryClient.invalidateQueries("getRestaurantType");

        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    const updateProjectMutation = useMutation(querydata.insertRestaurantType, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            onInvalidate();
            closeModal(false);
            toast.dismiss();
            toast.success(data.message);
            setValues({name:""})


            resetForm(); // Reset the form values
            setIsLoading(false);         
            // queryClient.invalidateQueries("getRestaurantType");

        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });

    console.log("setValues",values)
  return (
    <>
        <Modal show={show} onHide={handalCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            name="name"
                            type="text"
                            placeholder="Enter restaurant type name"
                            maxLength={100}
                            value={values?.name}
                            onChange={handleChange}
                        />
                        {touched.name && errors.name && errors.name ? (
                            <p className="form-error text-danger">{errors.name}</p>
                        ) :
                            null}

                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button
                    className='btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2'
                     variant="primary"
                        onClick={handleSubmit}
                    >
                        {isLoading ? (
                            <div
                                className="spinner-border  text-white"
                                role="status"
                            ></div>
                        ) : (
                            "SAVE"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal> 
    </>
  )
}

export default ResortTypeModal
